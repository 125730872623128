<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <b-card v-else>
          <div>
            <!-- Alert Info: Input Fields -->

            <validation-observer ref="alertValidation">
              <b-form @submit.prevent="validationForm">
                <b-row>
                  <!-- Field: color_text -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Couleur du texte"
                      label-for="color_text"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="color_text"
                        rules="required|max:7|min:4"
                      >
                        <b-form-input
                          id="color_text"
                          v-model="alert.color_text"
                          placeholder="#ffffff"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['color_text']"
                          class="text-danger"
                        >{{ server_errors['color_text'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: color_background -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Couleur du fond"
                      label-for="color_background"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="color_background"
                        rules="required|max:7|min:4"
                      >
                        <b-form-input
                          id="color_background"
                          v-model="alert.color_background"
                          placeholder="#000000"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['color_background']"
                          class="text-danger"
                        >{{ server_errors['color_background'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: text_fr -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Texte fr"
                      label-for="text_fr"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="text_fr"
                        rules="max:1000|min:3"
                      >
                        <b-form-textarea
                          id="textarea-default"
                          v-model="alert.text_fr"
                          placeholder="Textarea"
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['text_fr']"
                          class="text-danger"
                        >{{ server_errors['text_fr'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: text_en -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Texte en"
                      label-for="text_en"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="text_en"
                        rules="max:1000|min:3"
                      >
                        <b-form-textarea
                          id="textarea-default"
                          v-model="alert.text_en"
                          placeholder="Textarea"
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['text_en']"
                          class="text-danger"
                        >{{ server_errors['text_en'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: text_de -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Texte de"
                      label-for="text_de"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="text_de"
                        rules="max:1000|min:3"
                      >
                        <b-form-textarea
                          id="textarea-default"
                          v-model="alert.text_de"
                          placeholder="Textarea"
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['text_de']"
                          class="text-danger"
                        >{{ server_errors['text_de'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: text_it -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Texte it"
                      label-for="text_it"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="text_it"
                        rules="max:1000|min:3"
                      >
                        <b-form-textarea
                          id="textarea-default"
                          v-model="alert.text_it"
                          placeholder="Textarea"
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['text_it']"
                          class="text-danger"
                        >{{ server_errors['text_it'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <!-- Field: text_es -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Texte es"
                      label-for="text_es"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="text_es"
                        rules="max:1000|min:3"
                      >
                        <b-form-textarea
                          id="textarea-default"
                          v-model="alert.text_es"
                          placeholder="Textarea"
                          rows="3"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['text_es']"
                          class="text-danger"
                        >{{ server_errors['text_es'][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Pltforms -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Platformes"
                      label-for="platforms"
                    >
                      <v-select
                        v-model="platforms"
                        :dir="'ltr'"
                        :options="platformsOptions"
                        multiple
                        :clearable="true"
                        input-id="platforms"
                      />
                      <small
                        v-if="server_errors && server_errors['platform_id']"
                        class="text-danger"
                      >{{ server_errors['platform_id'][0] }}</small>
                    </b-form-group>
                  </b-col>

                  <!-- Field: is_visible -->
                  <b-col
                    cols="2"
                    md="2"
                  >
                    <b-form-group
                      label="Visibilité"
                      label-for="is_visible"
                    >
                      <b-form-checkbox
                        id="is_visible"
                        v-model="alert.is_visible"
                        :value="true"
                        :unchecked-value="false"
                      >
                        <span class="vs-label">{{ alert.is_visible ? 'oui' : 'non' }}</span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Action Buttons -->
                <b-button
                  variant="primary"
                  type="submit"
                  :disabled="formLoading"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                >
                  <b-spinner
                    v-show="formLoading"
                    small
                  />
                  Enregistrer
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  max, max_value, min, min_value, required,
} from '@core/utils/validations/validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BForm, BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BMedia,
  BRow,
  BSpinner,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import axiosIns from '@/libs/axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'AlertsCreate',
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    vSelect,
    BSpinner,
    BInputGroup,
    BFormCheckbox,
    BFormTextarea,
  },
  data() {
    return {
      loading: true,
      formLoading: false,
      platformsOptions: [],
      platforms: [],
      required,
      max,
      min,
      max_value,
      min_value,
      alert: {
        color_text: '#',
        color_background: '#',
        is_visible: true,
        text_fr: '',
        text_en: '',
        text_de: '',
        text_it: '',
        text_es: '',
      },
      alertReference: null,
      server_errors: null,
    }
  },
  mounted() {
    this.fetchPlatforms()
  },
  methods: {
    async fetchPlatforms() {
      try {
        this.loading = true
        const platforms = await axiosIns.get('/platforms/minimal')
        platforms.data.forEach(el => {
          this.platformsOptions.push({
            label: el.name,
            value: el.uuid,
          })
        })
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async submitForm() {
      try {
        this.formLoading = true
        this.server_errors = null
        const form = new FormData()
        form.append('color_text', this.alert.color_text)
        form.append('color_background', this.alert.color_background)
        form.append('is_visible', this.alert.is_visible)
        form.append('text_fr', this.alert.text_fr)
        form.append('text_en', this.alert.text_en)
        form.append('text_de', this.alert.text_de)
        form.append('text_it', this.alert.text_it)
        form.append('text_es', this.alert.text_es)
        this.platforms.forEach(platform => {
          form.append('platform_id[]', platform.value)
        })
        await axiosIns.post('alerts', form)
        await this.$router.push({
          path: '/alerts',
        })
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'L\'alerte à bien été créée',
            },
          },
          { timeout: 5000 },
        )
        this.formLoading = false
      } catch (err) {
        this.formLoading = false
        this.server_errors = err.response.data.errors
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    validationForm() {
      this.$refs.alertValidation.validate().then(success => {
        if (success) {
          this.submitForm()
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
